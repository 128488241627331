import { type SignUpFormData, signUpFormResolver } from '@elohello/auth';
import { Link } from '@remix-run/react';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { RemixFormProvider, useRemixForm } from 'remix-hook-form';
import { Button } from '~/components/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/form';
import { Input } from '~/components/input';
import { VStack } from '~/components/stack';
import { ROUTES } from '~/utils/routes';

export function SignUpForm() {
  const form = useRemixForm<SignUpFormData>({
    resolver: signUpFormResolver,
    defaultValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      trackEvent({ event: 'auth_sign_up' });
    }
  }, [form.formState.isSubmitSuccessful, trackEvent]);

  return (
    <RemixFormProvider {...form}>
      <form
        onSubmit={form.handleSubmit}
        method="POST"
        className="flex flex-col gap-8"
      >
        <VStack className="gap-4">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Username"
                    autoComplete="username"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Confirm password"
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </VStack>
        <Button isLoading={form.formState.isSubmitting} size="lg" type="submit">
          Create account
        </Button>
        <span className="text-sm text-muted-foreground">
          Already have an account?{' '}
          <Link
            to={ROUTES.AUTH.SIGN_IN}
            className="font-medium text-foreground"
          >
            Sign in
          </Link>
          .
        </span>
        <span className="text-xs text-muted-foreground">
          By creating an account, you agree to the{' '}
          <Link to={ROUTES.LEGAL.TERMS_OF_SERVICE}>Terms of Service</Link>. For
          more information see our{' '}
          <Link to={ROUTES.LEGAL.PRIVACY_POLICY}>Privacy Policy</Link>. We will
          send you information about our products and services to the email
          address registered to your account. You can decline this use of your
          email address free of charge at any time by writing an email to{' '}
          <a href="mailto:contact@elohello.com">contact@elohello.com</a> or by
          clicking the unsubscribe link in our emails.
        </span>
      </form>
    </RemixFormProvider>
  );
}
