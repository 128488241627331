import type { APIMethodParams } from '@elohello/service-utils/react-query';
import { z } from 'zod';
import { signInResponseSchema } from './sign-in.ts';

export const signUpRequestSchema = z.object({
  username: z
    .string()
    .min(8, { message: 'Username must contain at least 8 characters' }),
  email: z.string().email(),
  password: z
    .string()
    .min(8, { message: 'Password must be at least 8 characters long' }),
});

export const signUpResponseSchema = signInResponseSchema;

export type SignUpParams = z.infer<typeof signUpRequestSchema>;
export type SignUpResponse = z.infer<typeof signUpResponseSchema>;

export function signUp({ client, params }: APIMethodParams<SignUpParams>) {
  return client
    .url('/auth/register')
    .post(signUpRequestSchema.parse(params))
    .valid(signUpResponseSchema);
}
