import {
  type SignUpFormData,
  signUp,
  signUpFormResolver,
} from '@elohello/auth';
import { isHTTPClientError } from '@elohello/http-client';
import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  json,
} from '@remix-run/node';
import { redirect } from '@remix-run/react';
import { getValidatedFormData } from 'remix-hook-form';
import { Logo } from '~/components/logo';
import { VStack } from '~/components/stack';
import { SignUpForm } from '~/routes/auth.sign-up/sign-up-form';
import { authenticator } from '~/services/auth.server';
import { serverApiClient } from '~/services/client.server';
import { authSessionStorage } from '~/services/session.server';
import { ROUTES } from '~/utils/routes';

function AuthSignUpPage() {
  return (
    <VStack className="gap-12">
      <VStack className="gap-4">
        <h2 className="text-3xl font-bold">
          Welcome to <Logo />
        </h2>
        <p className="text-muted-foreground">
          Taste the sweetness of creativity at your fingertips
        </p>
      </VStack>
      <SignUpForm />
    </VStack>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  return await authenticator.isAuthenticated(request, {
    successRedirect: ROUTES.FEED.BROWSE,
  });
}

export async function action({ request }: ActionFunctionArgs) {
  const {
    data,
    errors,
    receivedValues: defaultValues,
  } = await getValidatedFormData<SignUpFormData>(request, signUpFormResolver);

  if (errors) {
    return json({ errors, defaultValues });
  }

  try {
    const user = await signUp({ client: serverApiClient, params: data });
    const headers = new Headers();

    const session = await authSessionStorage.getSession(
      request.headers.get('Cookie'),
    );

    session.set('user', {
      accessToken: user.accessToken.token,
      refreshToken: user.refreshToken.token,
      expiresAt: user.accessToken.expiresAt,
    });
    session.set('strategy', 'elohello-credentials');
    headers.set('Set-Cookie', await authSessionStorage.commitSession(session));

    return redirect(ROUTES.FEED.BROWSE, { headers });
  } catch (error) {
    if (isHTTPClientError(error) && error.status === 406) {
      return json({
        errors: {
          email: {
            message: 'This email has already been taken',
          },
        },
      });
    }

    console.error(error);

    throw new Response(null, { status: 500 });
  }
}

export const shouldRevalidate = () => false;

export default AuthSignUpPage;
