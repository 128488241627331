import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { signUpRequestSchema } from '../methods/sign-up.ts';

export const signUpFormSchema = signUpRequestSchema
  .pick({
    email: true,
    username: true,
    password: true,
  })
  .and(
    z.object({
      confirmPassword: z.string(),
    }),
  )
  .refine(
    ({ password, confirmPassword }) => {
      return password === confirmPassword;
    },
    {
      message: 'Passwords must match',
      path: ['confirmPassword'],
    },
  );

export const signUpFormResolver = zodResolver(signUpFormSchema);
export type SignUpFormData = z.infer<typeof signUpFormSchema>;
